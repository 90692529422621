import Api from "../../../Api"

const END_POINT_CREATE_ITEM_HV_SERVICEMATERIAPRIMA = 'user/roadmap/materia-prima/create';
const END_POINT_UPDATE_ITEM_HV_SERVICEMATERIAPRIMA = 'user/roadmap/materia-prima/update';
const END_POINT_DELETE_ITEM_HV_SERVICEMATERIAPRIMA = 'user/roadmap/materia-prima/delete';

export default {
  CreateItemHVMateriaPrima(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_HV_SERVICEMATERIAPRIMA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemHVMateriaPrima(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_HV_SERVICEMATERIAPRIMA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemHVMateriaPrima(id, auth){
    return Api.delete(END_POINT_DELETE_ITEM_HV_SERVICEMATERIAPRIMA + '/' + id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  }
}