<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Hoja viajera"
      subtitle="| Editar hoja viajera"
      class="heading-block"
    />    

    <div>
      <base-block rounded title="">
        <div class="block-content font-size-sm">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col sm="8">
                <b-row class="mt-1">
                  <b-col sm="3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Piezas/lotes: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.piezas_lotes"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Cantidad Procesada: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.cantidad_procesada"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Diferencia peso teórico: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.diferencia_peso_teorico"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          SCRAP: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.scrap"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Kg Producto Terminado: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.kg_producto_terminado"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Responsable: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.nombre_responsable"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Revisó: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.nombre_reviso"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Planeación: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.planeacion"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Producción: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.produccion"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Inspección: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.inspeccion"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Entrada: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.roadmap.entrada"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>


                </b-row>
              </b-col>

              <b-col sm="4" class="rounded border pt-2">
                <FileSystem v-if="quote_id != ''" view="cotizacion" :idItemView="quote_id" :files="files"></FileSystem>
              </b-col>
            </b-row>
            <br />
            <template v-if="this.form.cotizacion_id != ''">
              <b-row class="ml-4 mt-1">
                <b-col sm="3" style="left: 70%;">
                  <b-form-group label-for="no_cliente">
                    <template #label>
                        SELECCIONE MATERIA PRIMA
                    </template>
                    <select :disabled="form.num_piezas==''" v-model="select_option" @change="Option($event)" class="form-control">
                        <!--<option value="1">SERVICIO</option>-->
                        <option value="2">MATERIA PRIMA</option>
                    </select>
                    <span  style="color:red;" v-if="form.num_piezas==''">Escriba el # de piezas primero</span>
                  </b-form-group>
                </b-col>
                                    
                <b-row>
                  <!-- MODAL -->
                  <b-modal
                    id="modal-items"
                    size="xl"
                    body-class="p-0"
                    hide-footer
                    hide-header
                    >
                    <div class="block block-rounded block-themed block-transparent mb-0">
                      <div class="block-header bg-primary-dark">
                        <h3 class="block-title">
                          <i class="fa fa-user mr-1"></i> Lista
                        </h3>
                          <div class="block-options">
                            <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-items')"></i>
                          </div>
                      </div>
                      <div class="block-content font-size-sm">
                        <b-row>
                          <b-col offset-sm="9" sm="2">
                            <b-form inline>
                              <b-form-group label-for="no_cliente">
                                <template>
                                  Buscar:
                                </template>
                                <b-form-input
                                  v-model="searchTerm"
                                  size="sm"
                                ></b-form-input>
                              </b-form-group>
                            </b-form>
                          </b-col>
                        </b-row>
                        <div class="table-responsive">
                          <template v-if="select_option==1">
                            <b-table  
                              @row-clicked="SelectItemsServicios"
                              :items="servicios"
                              :fields="fields_servicios"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==2">
                          <b-table 
                              @row-clicked="SelectItemsMateriaP"
                              :items="materiapFilter"
                              :fields="fields_materiap"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==3">
                            <b-table 
                              @row-clicked="SelectItemsMaquinaria"
                              :items="maquinariasListFilter"
                              :fields="fields_maquinaria_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==4">
                            <b-table
                              @row-clicked="SelectItemsOperadores"
                              :items="operadoresListFilter"
                              :fields="fields_operador_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                         
                        </div>
                      </div>
                      <b-row>
                        <b-col sm="7" md="2" class="my-1">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalMateriapFilter"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                      <div class="block-content block-content-full text-right border-top">
                        <b-button
                          variant="alt-primary"
                          class="mr-1"
                          @click="$bvModal.hide('modal-items')"
                        >Cerrar</b-button>
                      </div>
                    </div>
                  </b-modal>
                  <!-- FIN MODAL -->
                </b-row>
              </b-row>

              <b-row>
                <!-- === MATERIA PRIMA === -->
                <div class="container">
                  <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                          <div class="col-2" id="titulo">
                            <p>Materia Prima</p>
                          </div>
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                        </div>
                      </div>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1" v-for="(data, index_Q_P_T_S_M_P) in quoteInfo.roadmap.materia_prima" :key="data.index_Q_P_T_S_M_P">
                          <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button id="button_accordion" block v-b-toggle="'accordion-'+index_Q_P_T_S_M_P" variant="info">{{data.materia_prima_codigo ? data.materia_prima_codigo : data.codigo}} - {{data.materia_prima_descripcion ? data.materia_prima_descripcion : data.descripcion}}</b-button>
                            <i class="fa fa-trash" @click="DeletedSelectMateriaP(index_Q_P_T_S_M_P)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            <i class="fa fa-save" @click="data.id > 0 ? ActualizarItemMateriaPrima(index_Q_P_T_S_M_P) : CrearItemMateriaPrima(index_Q_P_T_S_M_P)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                          </b-card-header>
                          <b-collapse :id="'accordion-'+index_Q_P_T_S_M_P" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                              <br><br>
                              <b-row class="ml-4 mt-12">
                                <b-col sm="12">
                                  <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                    <b-col sm="12">
                                      <b-form-group>
                                        <template #label>
                                          ESPECIFICACIONES
                                        </template>
                                      </b-form-group>
                                    </b-col>
                                    <b-row class="ml-1 w-100">
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Código
                                          </template>
                                          <b-form-input
                                            disabled
                                            size="sm"
                                            v-model="data.codigo"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="3">
                                        <b-form-group>
                                          <template #label>
                                            Descripción
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.descripcion"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Largo Estándar {{data.largo}}
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.largo"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Largo Estándar (mm)
                                          </template>
                                          <b-form-input
                                            disabled
                                            :value="data.largo*10"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Familia
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.materia_prima_family_name"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row class="ml-1 mt-3 w-100">
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Peso teórico
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.peso_teorico"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Peso x mm<sup>2</sup>
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.peso_mm2"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <b-col sm="12" class="mt-4" v-show="vercostos">
                                      <b-form-group>
                                        <template #label>
                                          COSTOS
                                        </template>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="1" v-show="vercostos">
                                      <b-form-group label-for="costo">
                                        <template #label>
                                          Costo
                                        </template>
                                        <b-form-input
                                          :value="data.costo ? data.costo : data.costo_ferrebaztan ? data.costo = data.costo_ferrebaztan : data.service_type.costo_ferrebaztan ? data.costo = data.service_type.costo_ferrebaztan : -1"
                                          v-model="data.costo"
                                          size="sm"
                                          :disabled="cotizarPorPV"
                                        ></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="1" v-show="vercostos">
                                      <b-form-group label-for="margen">
                                        <template #label>
                                          Margen
                                        </template>

                                        <b-form-input
                                          v-if="cotizarPorPV" 
                                          v-model="data.margen"
                                          :value="data.margen = 0"
                                          size="sm"
                                          disabled
                                        ></b-form-input>

                                        <!--data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen =-->


                                        <b-form-input
                                          v-if="!cotizarPorPV"
                                          v-model="data.margen"
                                          :value="data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen = -1"
                                          size="sm"
                                        ></b-form-input>

                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="4" v-show="vercostos">
                                      <b-row>
                                        <b-col sm="1">
                                          <b-form-group>
                                            <template #label>
                                              &nbsp;
                                            </template>
                                            <b-input-group size="sm" prepend="">
                                              <input type="checkbox" @click="cotizarPorPV=!cotizarPorPV" :checked="cotizarPorPV" />
                                            </b-input-group>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group label-for="pv">
                                            <template #label>
                                              Cotizar por PV Kilo
                                            </template>
                                            <b-input-group size="sm" prepend="$">
                                              <b-form-input
                                                v-if="!cotizarPorPV"
                                                :value="data.pv ? data.pv = ((((data.costo*data.margen)/100))+(parseInt(data.costo))) : data.pv = data.pv_from_services"
                                                v-model="data.pv"
                                                size="sm"
                                                disabled
                                              ></b-form-input>
                                              <b-form-input
                                                  v-if="cotizarPorPV"
                                                  :value="data.pv ? data.pv : data.pv_from_services ? data.pv = data.pv_from_services : data.service_type.pv ? data.pv = data.service_type.pv : data.pv = -1"
                                                  v-model="data.pv"
                                                  size="sm"
                                                ></b-form-input>
                                            </b-input-group>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <br><br>
                                  <b-row style="position:relative;"> 
                                    <b-col sm="8" >
                                      <b-row>
                                        <b-col sm="4" >
                                          <!-- seccion 1 -->
                                          <b-row>
                                            <b-col sm="12" >
                                              <b-form-group>
                                                <template #label>
                                                  LAMINAS/PLACAS A COTIZAR
                                                </template>
                                              </b-form-group>
                                            </b-col>
                                            <b-col sm="8" >
                                              <b-form-group label-for="largo_solicitado">
                                                <template #label>
                                                  # Laminas/Placas
                                                </template>
                                                <b-form-input
                                                  v-model="data.no_laminas_placas"
                                                  v-b-tooltip.hover title="Escriba el número de laminas/placas"
                                                  size="sm"
                                                ></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                        <b-col sm="8" >
                                          <b-row>
                                            <!-- seccion 2 -->
                                            <b-col sm="12">
                                              <b-form-group>
                                                <template #label>
                                                  MATERIAL POR CORTE A COTIZAR
                                                </template>
                                              </b-form-group>
                                            </b-col>
                                            <b-row>
                                              <b-col sm="6">
                                                <b-form-group label-for="largo_solicitado">
                                                  <template #label>
                                                      Largo Solicitado (mm)
                                                  </template>
                                                  <b-form-input
                                                    v-b-tooltip.hover title="Escriba el largo solicitado (mm)"
                                                    id="largo_solicitado"
                                                    v-model="data.largo_solicitado"
                                                    size="sm"
                                                  ></b-form-input>
                                                <!--    Precio:
                                                    $ {{ ((data.largo_solicitado * data.ancho) * data.peso_mm2) * data.pv }}
                                                --></b-form-group>
                                              </b-col>
                                              <b-col sm="6">
                                                <b-form-group label-for="ancho_solicitado">
                                                  <template #label>
                                                      Ancho solicitado (mm)
                                                  </template>
                                                  <b-form-input
                                                    v-b-tooltip.hover title="Escriba el ancho solicitado (mm)"
                                                    id="largo_solicitado"
                                                    v-model="data.ancho_solicitado"
                                                    size="sm"
                                                  ></b-form-input>
                                                </b-form-group>
                                              </b-col>    
                                            </b-row>
                                            <!-- fin seccion 2 -->
                                          </b-row>
                                        </b-col>
                                      </b-row>

                                      <b-col sm="12" class="mt-4">
                                        <b-form-group>
                                          <template #label class="text-danger">
                                              TOTAL KILOS
                                          </template>
                                        </b-form-group>
                                      </b-col>

                                      <b-row>
                                        <b-col sm="4">
                                          <b-form-group label-for="kilos_solicitados">
                                            <template #label>
                                              Kilos Totales
                                            </template>
                                            <b-form-input
                                              disabled
                                              :value="data.kilos_totales = data.no_laminas_placas * data.peso_teorico"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>

                                          + 

                                        <b-col sm="4">
                                          <b-form-group label-for="kilos_solicitados">
                                            <template #label>
                                              Kilos Solicitados
                                            </template>
                                            <b-form-input
                                              disabled
                                              :value="data.kilos_solicitados = (data.largo_solicitado * data.ancho_solicitado) * data.peso_mm2"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col> 

                                          =

                                        <b-col sm="2" class="ml-4">
                                          <b-form-group label-for="total">
                                            <template #label>
                                              Total Kilos
                                            </template>
                                            <b-form-input
                                              :value="data.total_de_kilos = data.kilos_totales + data.kilos_solicitados"
                                              disabled
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>

                                      <b-row class="mt-4">
                                        <b-col sm="6">
                                          <b-form-group label-for="kilos_solicitados">
                                            <template #label>
                                              Comentarios
                                            </template>
                                            <b-form-textarea
                                              v-model="data.comentarios"
                                              size="sm"
                                            ></b-form-textarea>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-col>

                                    <div class="v-line" v-show="vercostos"></div>

                                    <b-col sm="4" v-show="vercostos">
                                      <b-col sm="12">
                                        <b-form-group>
                                          <template #label>
                                            COSTOS TOTALES
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-row class="ml-4 mt-1" v-show="vercostos">
                                        <b-col sm="5">
                                          <b-form-group label-for="total">
                                            <template #label>
                                              Total
                                            </template>
                                            $ {{ data.total_dinero = 0 }}
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row class="ml-4 mt-1" v-show="vercostos">
                                        <b-col sm="5">
                                          <b-form-group label-for="total">
                                            <template #label>
                                              Total x Pza
                                            </template>
                                            $ {{ data.total_x_pieza = 0}}
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row class="ml-4 mt-1" v-show="vercostos">
                                        $ {{ data.descuento = 0}}
                                        <b-col sm="5">
                                          <b-form-group label-for="total">
                                            <template #label>
                                              Descuento %
                                            </template>
                                            <select class="form-control" v-model="data.descuento">
                                              <option value="0.00" :selected="[ (data.descuento) ? 'selected':'' ]">0%</option>
                                              <option value="5.00">5%</option>
                                              <option value="10.00">10%</option>
                                              <option value="20.00">20%</option>
                                            </select>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row class="ml-4 mt-4" v-show="vercostos">
                                        <b-col sm="8">
                                          <b-form-group label-for="total">
                                            <template #label>
                                              Total (con/sin Descuento)
                                            </template>
                                            $ {{  data.total_con_descuento = 0 }}
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-col>                                                           
                                  </b-row>
                                  <br><br>
                                  <br><br>
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <!-- === FIN DE MATERIA PRIMA === -->
              </b-row>

              <!-- tabla para almacenar las incidencias en hoja viajera -->
              <b-row class="mt-4 mb-4">
                <div class="table-responsive" style="min-height: 450px;">
                  <p style="border: none!important; margin-bottom: 10px!important; text-align: center;">TIEMPO DE PRODUCCIÓN REAL</p>
                  <table class="table tabla-incidencias">
                    <thead>
                      <tr>
                        <th>Fecha de inicio</th>
                        <th><p style="width: 20px; margin-bottom: 0px;"># de partida</p></th>
                        <th>Máquina</th>
                        <th>Operador</th>
                        <th><p style="width:100px; margin-bottom: 0px;">Hora de inicio</p></th>
                        <th><p style="width:100px; margin-bottom: 0px;">Hora de comida</p></th>
                        <th><p style="width:100px; margin-bottom: 0px;">Hora final</p></th>
                        <th>Horas trabajadas</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(incidencia, index) in quoteInfo.roadmap.history_items" :key="index">
                        <td>
                          <b-form-datepicker
                            placeholder="" 
                            class="mb-2"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="incidencia.fecha_inicio">
                          </b-form-datepicker>
                        </td>
                        <td>
                          <select class="form-control" v-model="incidencia.q_f_p_id">
                            <option>Seleccione</option>
                            <option :value="data.q_f_p_id" v-for="(data, index) in selected_list_items_producto_terminado" :key="index">
                              {{ data.no_parte }} -- {{ data.descripcion }} -- No de piezas: {{ data.no_piezas }} -- Comentario: {{ data.comentarios }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <select class="form-control" v-model="incidencia.machinery_id">
                            <option>Seleccione</option>
                            <option :value="data.id" v-for="(data, index) in maquinariasList" :key="index">
                              {{ data.numero_maquina }} - {{ data.nombre_maquina }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <select class="form-control" v-model="incidencia.collaborator_id">
                            <option>Seleccione</option>
                            <option :value="data.id" v-for="(data, index) in operadoresList" :key="index">
                              {{ data.no_empleado }} - {{ data.nombre_completo }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <p style="display:none;">{{ incidencia.hora_inicio ? incidencia.hora_inicio : incidencia.hora_inicio = "00:00" }}</p>
                          <b-input-group class="mb-3">
                            <b-form-input
                              :id="'example-input-1'+index"
                              v-model="incidencia.hora_inicio"
                              type="text"
                              placeholder="HH:mm"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-timepicker
                                v-model="incidencia.hora_inicio"
                                button-only
                                right
                                locale="es"
                                :aria-controls="'example-input-1'+index"
                              ></b-form-timepicker>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-timepicker v-if="false" locale="es" placeholder="" v-model="incidencia.hora_inicio" class="local-date-pick"></b-form-timepicker>
                          <p style="display:none;">{{ incidencia.hora_inicio.split(':').slice(0,2).join(':') }}</p>
                          <p style="display:none;">{{incidencia.hora_inicio_hora = parseInt(incidencia.hora_inicio.split(':').slice(0,2).at(0))}}</p>
                          <p style="display:none;">{{incidencia.hora_inicio_minuto = parseInt(incidencia.hora_inicio.split(':').slice(0,2).at(1))}}</p>
                        </td>
                        <td>
                          <p class="mb-0">Inicio</p>
                          <p style="display:none;">{{ incidencia.hora_comida_incio != null ? incidencia.hora_comida_incio : incidencia.hora_comida_incio = "00:00" }}</p>
                          <b-input-group class="mb-3">
                            <b-form-input
                              :id="'example-input-1'+index"
                              v-model="incidencia.hora_comida_incio"
                              type="text"
                              placeholder="HH:mm"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-timepicker
                                v-model="incidencia.hora_comida_incio"
                                button-only
                                right
                                locale="es"
                                :aria-controls="'example-input-1'+index"
                              ></b-form-timepicker>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-timepicker v-if="false" locale="es" placeholder="" v-model="incidencia.hora_comida_incio"></b-form-timepicker>
                          <p style="display:none;">{{ incidencia.hora_comida_incio.split(':').slice(0,2).join(':') }}</p>
                          <p style="display:none;">{{incidencia.hora_comida_inicio_hora = parseInt(incidencia.hora_comida_incio.split(':').slice(0,2).at(0))}}</p>
                          <p style="display:none;">{{incidencia.hora_comida_inicio_minuto = parseInt(incidencia.hora_comida_incio.split(':').slice(0,2).at(1))}}</p>
                          <p class="mb-0 mt-2">Fin</p>
                          <p style="display:none;">{{ incidencia.hora_comida_fin != null ? incidencia.hora_comida_fin : incidencia.hora_comida_fin = "00:00" }}</p>
                          <b-input-group class="mb-3">
                            <b-form-input
                              :id="'example-input-1'+index"
                              v-model="incidencia.hora_comida_fin"
                              type="text"
                              placeholder="HH:mm"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-timepicker
                                v-model="incidencia.hora_comida_fin"
                                button-only
                                right
                                locale="es"
                                :aria-controls="'example-input-1'+index"
                              ></b-form-timepicker>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-timepicker v-if="false" locale="es" placeholder="" v-model="incidencia.hora_comida_fin"></b-form-timepicker>
                          <p style="display:none;">{{ incidencia.hora_comida_fin.split(':').slice(0,2).join(':') }}</p>
                          <p style="display:none;">{{incidencia.hora_comida_fin_hora = parseInt(incidencia.hora_comida_fin.split(':').slice(0,2).at(0))}}</p>
                          <p style="display:none;">{{incidencia.hora_comida_fin_minuto = parseInt(incidencia.hora_comida_fin.split(':').slice(0,2).at(1))}}</p>
                        </td>
                        <td>
                          <p style="display:none;">{{ incidencia.hora_final != null ? incidencia.hora_final : incidencia.hora_final = "00:00" }}</p>
                          <b-input-group class="mb-3">
                            <b-form-input
                              :id="'example-input-1'+index"
                              v-model="incidencia.hora_final"
                              type="text"
                              placeholder="HH:mm"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-timepicker
                                v-model="incidencia.hora_final"
                                button-only
                                right
                                locale="es"
                                :aria-controls="'example-input-1'+index"
                              ></b-form-timepicker>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-timepicker v-if="false" locale="es" placeholder="" v-model="incidencia.hora_final"></b-form-timepicker>
                          <p style="display:none;">{{ incidencia.hora_final.split(':').slice(0,2).join(':') }}</p>
                          <p style="display:none;">{{incidencia.hora_fin_hora = parseInt(incidencia.hora_final.split(':').slice(0,2).at(0))}}</p>
                          <p style="display:none;">{{incidencia.hora_fin_minuto = parseInt(incidencia.hora_final.split(':').slice(0,2).at(1))}}</p>
                          <p style="display:none;">Horas recorridas durante el día (inicio): {{ incidencia.minutos_totales_hora_inicio = (incidencia.hora_inicio_hora*60) + incidencia.hora_inicio_minuto}}</p>
                          <p style="display:none;">Horas recorridas durante el día (fin): {{ incidencia.minutos_totales_hora_fin = (incidencia.hora_fin_hora*60) + incidencia.hora_fin_minuto}}</p>
                          <p style="display:none;">Horas recorridas durante el dia (para inicio de la comida): {{ incidencia.minutos_totales_hora_comida_inicio = (incidencia.hora_comida_inicio_hora*60) + incidencia.hora_comida_inicio_minuto}}</p>
                          <p style="display:none;">Horas recorridas durante el dia (para fin de la comida): {{ incidencia.minutos_totales_hora_comida_fin = (incidencia.hora_comida_fin_hora*60) + incidencia.hora_comida_fin_minuto}}</p>
                          <p style="display:none;">Minutos totales de comida: {{ incidencia.minutos_totales_comida = incidencia.minutos_totales_hora_comida_fin - incidencia.minutos_totales_hora_comida_inicio }}</p>
                          <p style="display:none;">Minutos totales de inicio de jornada más mi comida (pe si entras a las 8am y te tomas 30 min de comida, es como si realmente comenzaras a trabajar 8.30): {{ incidencia.hora_inicio_real = incidencia.minutos_totales_hora_inicio + incidencia.minutos_totales_comida }}</p>
                          <p style="display:none;">Jornada total = {{ incidencia.minutos_trabajados =  incidencia.minutos_totales_hora_fin - incidencia.hora_inicio_real }}</p>
                          <p style="display:none;">Jornada total en horas= {{ incidencia.horas_trabajadas = (incidencia.minutos_trabajados / 60).toFixed(2) }}</p>
                        </td>
                        <td>
                          <b-form-input
                            v-model="incidencia.horas_trabajadas"
                            disabled
                            size="sm"
                            ></b-form-input>
                        </td>
                        <td>
                          <button class="btn btn-success" @click="incidencia.id > 0 ? ActualizarRoadmapHistoryItem(index) : CreateRoadmapHistoryItem(index)">Guardar</button>
                          <br />
                          <button class="btn btn-info mt-1" @click="AddIncidencia">Agregar</button>
                          <br />
                          <button class="btn btn-danger mt-1" @click="BorrarRoadmapHistoryItem(index)">Borrar</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-row>
            </template >
            <br />
          </form>
        </div>
        <div  class="block-content block-content-full text-right border-top">
          <b-button variant="alt-primary"  class="mr-1"  @click="Cancelar()">Cancelar</b-button>
          <b-button variant="primary" @click="ActualizarHojaViajera">Guardar hoja</b-button>
          <b-button variant="primary" class="ml-1" @click="quoteInfo.inspection_sheet != null ? ViewInspectionSheet() : SaveAndCreateInspectionsSheet()">Guardar y {{ quoteInfo.inspection_sheet != null ? "ver":"generar" }} Hoja de Inspección</b-button>
        </div>
        </base-block>
    </div>
  </div>
</template>

<script>

  import Servicios from "../../../api/admin/services/Services";
  import Clientes from "../../../api/admin/clientes/Clientes";
  import MateriaP from "../../../api/admin/materia_prima/Materia_Prima";
  import Maquinarias from "../../../api/admin/maquinaria/Maquinarias";
  import Empleados from "../../../api/admin/empleados/Empleados";
  import Producto_Terminado from "../../../api/admin/producto_terminado/Producto_Terminado";
  import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion";
  import MateriaPrima from "../../../api/admin/cotizacion/materia_prima/MateriaPrima";
  //import Maquinaria from "../../../api/admin/cotizacion/maquinaria/Maquinaria";
  //import QuoteProductoTerminado from "../../../api/admin/cotizacion/producto_terminado/ProductoTerminado"
  //import Archivos from '../../../api/admin/archivos/Archivos';

  import HojaViajera from "../../../api/admin/hoja_viajera/Hoja_Viajera";
  import HojaViajeraHistorial from "../../../api/admin/hoja_viajera/historial/Historial";
  import HojaViajeraMateriaPrima from "../../../api/admin/hoja_viajera/materia_prima/Materia_Prima";

  import HojaInspeccion from "../../../api/admin/hoja_inspeccion/Hoja_Inspeccion";

  import FileSystem from "../../../components/FileSystem.vue"

  import Swal from "sweetalert2";

  //import Dropzone from "dropzone";
  //import "dropzone/dist/dropzone.css";

  export default {

    components: {
      FileSystem
    },

    data() {
      return {
        searchTerm:'',
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        select_option:'',
        quoteInfo:{ roadmap : {} },
        servicios:[],
        materiap:[],
        clientes:[],
        maquinariasList:null,
        operadoresList:null,
        cotizacionInfo:[],
        currentQuote:0,
        cotizarPorPV:true,
        cotizarPorPVServicios:true,
        cotizarPorPVOperadores:true,
        vercostos:false,
        totalRows:0,
        form: {
          piezas_lotes:'',
          cantidad_procesada: '',
          diferencia_peso_teorico:'',
          scrap:'',
          kg_producto_terminado:'',
          planeacion:'',
          produccion:'',
          inspeccion:'',
          entrada:''
        },  
        incidenciasHV:[],
        fields_materiap: [
          {key: 'codigo',sortable: true, label: 'CÓDIGO MACRO'},
          {key: 'descripcion',sortable: true, label: 'DESCRIPCIÓN'},
          {key: 'materia_prima_family', formatter: value => {
              return value.name
          },sortable: true, label: 'FAMILIA'},
          {key: 'materia_prima_unit', formatter: value => {
              return value.name
          },sortable: true, label: 'UNIDAD'},
          {key: 'service_type', formatter: value => {
              return value.nombre.length ? value.nombre : 'No especificado'
          },sortable: true, label: 'SERVICIO'},
          {key: 'peso_teorico',sortable: true, label: 'PESO TEORICO'},
          {key: 'maximo',sortable: true, label: 'MÁXIMO'},
          {key: 'minimo',sortable: true, label: 'MÍNIMO'},
        ],
        select_list_items_materiap: [],
        selected_list_items_producto_terminado:null
      }
    },

    methods: {

      handleSubmit(){
        console.log("hand submit")
      },

      Cancelar(){
        this.$router.push({
          path: "/cotizacion/list"
        });
      },

      async GetQuoteInfo(id){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        await PreCotizacion.GetQuot(id,auth)
        .then((response) => {
          const responseQuoteInfo = response.data.data
          context.quoteInfo = responseQuoteInfo
          context.selected_list_items_producto_terminado = responseQuoteInfo.finished_products
          if(context.quoteInfo.roadmap.history_items.length == 0){
            context.AddIncidencia()
          }
          context.files = responseQuoteInfo.files
          console.log(context.quoteInfo)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      AllClientes() {
        let auth = JSON.parse(localStorage.autentication);
        Clientes.GetAllCustomers(auth)
          .then((response) => {
            this.clientes = response.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      },

      AllMaquinas(){
        let auth = JSON.parse(localStorage.autentication);
        Maquinarias.GetAllMaquinas(auth)
        .then((response) => {
          this.maquinariasList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      AllOperadores(){
        let auth = JSON.parse(localStorage.autentication);
        Empleados.GetAllEmpleados(auth)
          .then((response) => {
            this.operadoresList = response.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      },

      UpdateItemMateriaPrima(intemIndex){
        let auth = JSON.parse(localStorage.autentication);
        MateriaPrima.UpdateItemQuotMateriaPrima(JSON.stringify(this.select_list_items_materiap[intemIndex]),auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      SelectItemsMateriaP(data_select){
        let me = this        
        data_select.m_p_id = data_select.id
        data_select.id = 0
        me.quoteInfo.roadmap.materia_prima.push(data_select);
        me.$bvModal.hide("modal-items"); 
        me.select_option = ''; 
      },

      DeletedSelectMateriaP(data){
        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            if(this.quoteInfo.roadmap.materia_prima[data].id>0){
              console.log("eliminar de la base de datos")
              let auth = JSON.parse(localStorage.autentication);
              HojaViajeraMateriaPrima.DeleteItemHVMateriaPrima(this.quoteInfo.roadmap.materia_prima[data].id, auth)
              this.GetQuoteInfo(this.quote_id);
            } else {
              // solo elimar del arreglo local
              this.quoteInfo.roadmap.materia_prima.splice(data, 1);
            }
          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });
      },

      AddIncidencia(){
        const item = {
          id:0,
          roadmap_id:this.quoteInfo.roadmap.id,
          fecha_inicio:'',
          q_f_p_id:null,
          machinery_id:null,
          collaborator_id:null,
          hora_inicio:"00:00",
          hora_comida_incio:"00:00",
          hora_comida_fin:"00:00",
          hora_final:"00:00",
          horas_trabajadas:0
        }

        //console.log(item)
        this.quoteInfo.roadmap.history_items.push(item);
      },

      CreateRoadmapHistoryItem(itemIndex){
        const context = this
        let auth = JSON.parse(localStorage.autentication)
        const data = context.quoteInfo.roadmap.history_items[itemIndex]

        HojaViajeraHistorial.CreateRoadmapHistoryItem(data, auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      ActualizarRoadmapHistoryItem(itemIndex){
        const context = this
        let auth = JSON.parse(localStorage.autentication)
        const data = context.quoteInfo.roadmap.history_items[itemIndex]

        HojaViajeraHistorial.UpdateRoadmapHistoryItem(data, auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      BorrarRoadmapHistoryItem(itemIndex){
        const context = this
        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            let auth = JSON.parse(localStorage.autentication)
            const data = {
             roadmap_id : context.quoteInfo.roadmap.id,
             id : context.quoteInfo.roadmap.history_items[itemIndex].id
            }

            HojaViajeraHistorial.DeleteRoadmapHistoryItem(data, auth)
            .then((response) => {
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.GetQuoteInfo(context.quote_id)
            })
            .catch((error) => {
              const obj = error.response.data.errors;
              Object.entries(obj).forEach( elements => 
                Swal.fire({
                  title: "Que mal!",
                  text: elements[1][0],
                  icon: "error",
                })
              );
            });
          }
        });
      },

      Option(event) {
        let option = event.target.value
        let auth = JSON.parse(localStorage.autentication);

        switch (parseInt(option)) {
          case 1:
            Servicios.GetAllServices(auth)
            .then((response) => {
              this.servicios = response.data;
              this.$bvModal.show("modal-items");
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;

          case 2:
            MateriaP.GetAllMateriaPrima(auth)
            .then((response) => {
              this.materiap = response.data;
              this.$bvModal.show("modal-items");
            })
            .catch((error) => {
              console.log(error.data);
            });
            break
          
          case 3:
            Maquinarias.GetAllMaquinas(auth)
            .then((response) => {
              this.maquinariasList = response.data;
              this.$bvModal.show("modal-items");
            })
            .catch((error) => {
              console.log(error.data);
            });
            break

          case 4:
            Empleados.GetAllEmpleados(auth)
            .then((response) => {
              this.operadoresList = response.data;
              this.$bvModal.show("modal-items");
            })
            .catch((error) => {
              console.log(error.data);
            });
            break
          
          case 5:
            Producto_Terminado.GetAllProductoTerminado(auth)
            .then((response) => {
              this.productoTerminadoList = response.data;
              this.$bvModal.show("modal-items");
            })
            .catch((error) => {
              console.log(error.data);
            });
            break
        
          default:
            break;
        }
      },

      SaveAndCreateInspectionsSheet(){

        const context = this
        let auth = JSON.parse(localStorage.autentication);

        const data = {
          "quote_id":context.quote_id
        }

        HojaInspeccion.CreateHojaInspeccion(data, auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          //context.GetQuoteInfo(context.quote_id)
          context.$router.push({ path: '/hoja-de-inspeccion/edit/'+context.quote_id})
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      async ActualizarHojaViajera(){
        const context = this
        let auth = JSON.parse(localStorage.autentication)
        const data = context.quoteInfo.roadmap
        //const data = {}

        HojaViajera.UpdateHojaViajera(data,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      ViewInspectionSheet(){
        const context = this
        context.ActualizarHojaViajera().
        then(()  => {
          context.$router.push({ path: '/hoja-de-inspeccion/edit/'+context.quote_id})
        })
      },

      CrearItemMateriaPrima(itemIndex){

        const context = this
        context.quoteInfo.roadmap.materia_prima[itemIndex].roadmap_id = context.quoteInfo.roadmap.id
        let auth = JSON.parse(localStorage.autentication)
        const data = context.quoteInfo.roadmap.materia_prima[itemIndex]

        HojaViajeraMateriaPrima.CreateItemHVMateriaPrima(data, auth )
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      ActualizarItemMateriaPrima(itemIndex){

        const context = this
        context.quoteInfo.roadmap.materia_prima[itemIndex].roadmap_id = context.quoteInfo.roadmap.id
        let auth = JSON.parse(localStorage.autentication)
        const data = context.quoteInfo.roadmap.materia_prima[itemIndex]

        HojaViajeraMateriaPrima.UpdateItemHVMateriaPrima(data, auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      BorrarSelectMateriaP(id){
        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            const context = this
            if(id > 0){
                console.log("Hay que desde la base de datos")
                let auth = JSON.parse(localStorage.autentication)
                HojaViajeraMateriaPrima.DeleteItemHVMateriaPrima(id, auth)
                .then((response) => {
                  Swal.fire({
                    title: "Genial!",
                    text: response.data.message,
                    icon: "success",
                  });
                  context.GetQuoteInfo(context.quote_id)
                })
                .catch((error) => {
                  const obj = error.response.data.errors;
                  Object.entries(obj).forEach( elements => 
                    Swal.fire({
                      title: "Que mal!",
                      text: elements[1][0],
                      icon: "error",
                    })
                  );
                });
              } else if (id == 0){
                try{
                  //context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].materia_prima[0].splice(materiaPrimaArrayIndex,1)
                } catch (e) {
                  console.log(e)
                }
              } else {
                try{
                  //context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].materia_prima[0].splice(materiaPrimaArrayIndex,1)
                } catch (e) {
                  console.log(e)
                }
              }
          }
        });
      }
    },

    computed: {

      materiapFilter(){
        return this.materiap.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.codigo.toLowerCase().includes(this.searchTerm.toLowerCase()) || 
            item.descripcion.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      totalMateriapFilter(){
        return this.materiapFilter.length
      },

      maquinariasListFilter(){
        return this.maquinariasList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.nombre_maquina.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      operadoresListFilter(){
        return this.operadoresList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.no_empleado.toString().includes(this.searchTerm.toLowerCase()) ||
            item.nombre_completo.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      productoTerminadoListFilter(){
        return this.productoTerminadoList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.codigo.toLowerCase().includes(this.searchTerm.toLowerCase()) || 
            item.descripcion.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      totalSinUtilidad(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaSinUtilidad = 0
        let totalMateriaPrimaSinUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaSinUtilidad = (item_materia_prima.total_de_kilos * item_materia_prima.costo) / producto_terminado.no_piezas
            totalMateriaPrimaSinUtilidadPorPieza += totalMateriaPrimaSinUtilidad
          })
          /*if(producto_terminado.materia_prima[0].length > 0){
            totalMateriaPrimaSinUtilidadPorPieza += totalMateriaPrimaSinUtilidad
          }*/
        })
        //return totalMateriaPrimaSinUtilidadPorPieza

        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaSinUtilidad = 0
        let totalMaquinariaSinUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaSinUtilidad = ((maquina.minutos/60) * maquina.costo) / producto_terminado.no_piezas
            totalMaquinariaSinUtilidadPorPieza += totalMaquinariaSinUtilidad
          })
          /*if(producto_terminado.maquinaria[0].length > 0){
            totalMaquinariaSinUtilidadPorPieza += totalMaquinariaSinUtilidad
          }*/
        })
        //return totalMaquinariaSinUtilidadPorPieza

        /*
        * Tercera parte: "Operadores"
        */

        let totalOperadoresSinUtilidad = 0
        let totalOperadoresSinUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresSinUtilidad = ((operador.minutos/60) * operador.costo) / producto_terminado.no_piezas
            totalOperadoresSinUtilidadPorPieza += totalOperadoresSinUtilidad
          })
          /*if(producto_terminado.operadores[0].length > 0){
            totalOperadoresSinUtilidadPorPieza += totalOperadoresSinUtilidad
          }*/
        })
        //return totalOperadoresSinUtilidadPorPieza

        /**
        * Al final, lo que tenemos que retornar es la suma de las anteriores
        */

        return (totalMateriaPrimaSinUtilidadPorPieza + totalMaquinariaSinUtilidadPorPieza + totalOperadoresSinUtilidadPorPieza).toFixed(2)
      },

      totalConUtilidad(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaConUtilidad = 0
        let totalMateriaPrimaConUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaConUtilidad = item_materia_prima.total_con_descuento / producto_terminado.no_piezas
            console.log("totales de materia prima por producto terminado: ",totalMateriaPrimaConUtilidad)
            totalMateriaPrimaConUtilidadPorPieza += totalMateriaPrimaConUtilidad
          })

          /*if(producto_terminado.materia_prima[0].length > 0){
            totalMateriaPrimaConUtilidadPorPieza += totalMateriaPrimaConUtilidad
          }*/
        })
        // return totalMateriaPrimaConUtilidadPorPieza

        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaConUtilidad = 0
        let totalMaquinariaConUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaConUtilidad = maquina.total_con_descuento / producto_terminado.no_piezas
            totalMaquinariaConUtilidadPorPieza += totalMaquinariaConUtilidad
          })
          /*if(producto_terminado.maquinaria[0].length > 0){
            totalMaquinariaConUtilidadPorPieza += totalMaquinariaConUtilidad
          }*/
        })
        //return totalMaquinariaConUtilidadPorPieza

        /*
        * Tercera parte: "Operadores"
        */

        let totalOperadoresConUtilidad = 0
        let totalOperadoresConUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresConUtilidad = operador.total_con_descuento / producto_terminado.no_piezas
            totalOperadoresConUtilidadPorPieza += totalOperadoresConUtilidad
          })
          /*if(producto_terminado.operadores[0].length > 0){
            totalOperadoresConUtilidadPorPieza += totalOperadoresConUtilidad
          }*/
        })
        //return totalOperadoresConUtilidadPorPieza

        /**
        * Al final, lo que tenemos que retornar es la suma de las anteriores
        */

        console.log("total con utilidad de materia prima: ", totalMateriaPrimaConUtilidadPorPieza)
        console.log("total con utilidad de maquinaria: ", totalMaquinariaConUtilidadPorPieza)
        console.log("total con utilidad de operadores: ", totalOperadoresConUtilidadPorPieza)

        return (totalMateriaPrimaConUtilidadPorPieza + totalMaquinariaConUtilidadPorPieza + totalOperadoresConUtilidadPorPieza).toFixed(2)
      },

      utilidadBruta(){
        return (this.totalConUtilidad - this.totalSinUtilidad).toFixed(2)
      },

      utilidadBrutaPorcentaje(){
        // return ((this.utilidadBruta / this.totalSinUtilidad) * 100).toFixed(2)
        return ( this.utilidadBruta * 100 / this.totalConUtilidad ).toFixed(2)
      },

      totalSinUtilidadPorTotalDePiezas(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaSinUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaSinUtilidad += (item_materia_prima.total_de_kilos * item_materia_prima.costo)
          })
        })

        // return totalMateriaPrimaSinUtilidadPorPieza

        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaSinUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaSinUtilidad += (maquina.minutos/60) * maquina.costo
          })
        })

        // return totalMaquinariaSinUtilidadPorPieza


        /**
        * Tercera parte: "Operadores"
        */
        let totalOperadoresSinUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresSinUtilidad += (operador.minutos/60) * operador.costo
          })
        })

        console.log("total sin utilidad de materia prima: ", totalMateriaPrimaSinUtilidad)
        console.log("total sin utilidad de maquinaria: ", totalMaquinariaSinUtilidad)
        console.log("total sin utilidad de operadores: ", totalOperadoresSinUtilidad)

        return (totalMateriaPrimaSinUtilidad + totalMaquinariaSinUtilidad + totalOperadoresSinUtilidad).toFixed(2)

      },

      totalConUtilidadPorTotalDePiezas(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaConUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaConUtilidad += parseFloat(item_materia_prima.total_con_descuento)
          })
        })
        // return totalMateriaPrimaConUtilidad


        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaConUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaConUtilidad += parseFloat(maquina.total_con_descuento)
          })
        })

        // return totalMaquinariaConUtilidad


        /**
        * Tercera parte: "Operadores"
        */
        let totalOperadoresConUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresConUtilidad += parseFloat(operador.total_con_descuento)
          })
        })

        //return totalOperadoresConUtilidad

        return (totalMateriaPrimaConUtilidad + totalMaquinariaConUtilidad + totalOperadoresConUtilidad).toFixed(2)

      },

      totalUtilidadBruta(){
        return (this.totalConUtilidadPorTotalDePiezas - this.totalSinUtilidadPorTotalDePiezas).toFixed(2)
      },

      totalUtilidadBrutaPorcentaje(){
        // return ((this.totalUtilidadBruta / this.totalSinUtilidadPorTotalDePiezas) * 100).toFixed(2)
        return ( this.totalUtilidadBruta * 100 / this.totalConUtilidadPorTotalDePiezas ) .toFixed(2)
      }

    },

    mounted() {
      let me = this;
      me.quote_id = me.$route.params.id
      me.GetQuoteInfo(me.quote_id);
      me.AllMaquinas();
      me.AllOperadores();
    }
  };



</script>


<style scoped>
  #button_accordion{
    background: #272e38;
    border-color: #272e38;
  }

  #titulo {
    text-align: center;
  }

  hr#linea {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #030303;
  }

  hr{
    border-top: 1px solid #000000 !important;
  }

  .v-line{
    border-left: solid #e8dbdb;
    height: 57%;
    left: 63%;
    position: absolute;
  }

  .form-group{
    margin-bottom: 5px!important;
  }

  .tabla-incidencias{

  }

  .tabla-incidencias thead th{
    padding: 6px!important;
    font-size: 13px;
    width: 15%;
  }

  .tabla-incidencias tbody td{
    padding: 6px!important;
    font-size: 14px;
    width: 10%;
  }

  .local-date-pick label{
    display: none;
  }
</style>