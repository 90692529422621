import Api from "../../../Api"

export default {
  CreateRoadmapHistoryItem(data, auth){
    return Api.post(`user/roadmap/${data.roadmap_id}/history/create`, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateRoadmapHistoryItem(data, auth) {
    return Api.patch(`user/roadmap/${data.roadmap_id}/history/update`, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteRoadmapHistoryItem(data, auth) {
    return Api.delete(`user/roadmap/${data.roadmap_id}/history/delete` + '/' + data.id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  }
}