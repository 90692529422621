import Api from "../../Api";


const END_POINT_ALLMAQUINAS = 'user/machineries/all';
const END_POINT_CREATEMAQUINA = 'user/machineries/create';
const END_POINT_UPDATEMAQUINA = 'user/machineries/update';
const END_POINT_DELETEMAQUINA = 'user/machineries/delete';

export default {
    GetAllMaquinas(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLMAQUINAS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateMaquina(data, auth) {
        return Api.post(END_POINT_CREATEMAQUINA, {
            'numero_maquina': data.numero_maquina,
            'nombre_maquina': data.nombre_maquina,
            'costo_hora': data.costo_hora
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateMaquina(data, auth) {
        return Api.patch(END_POINT_UPDATEMAQUINA, {
            'id': data.id,
            'numero_maquina': data.numero_maquina,
            'nombre_maquina': data.nombre_maquina,
            'costo_hora': data.costo_hora
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByMaquina(id, auth) {
        return Api.delete(END_POINT_DELETEMAQUINA + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}